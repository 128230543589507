<template>
  <v-container>
    <v-row justify="center" align="center">
      <h1 style="margin-bottom: 30px">Advanced visit search</h1>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="10">
        <v-tabs
          v-model="selectedTab"
          fixed-tabs
          background-color="blue darken-4"
          dark
          @change="onTabChange"
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab> By date range </v-tab>
          <v-tab> By name, last name </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <v-row class="search-body">
              <v-col>
                <v-date-picker
                  :show-current="true"
                  v-model="dates"
                  range
                ></v-date-picker>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="search-body">
              <v-col md="4">
                <v-text-field
                  v-model="searchTokenByName"
                  required
                  hint="minimum 3 charachters"
                >
                  <template #label>
                    Search term
                    <span class="required"><strong>(required)</strong></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <v-btn :disabled="!allowSubmit" @click="onSearch" color="primary"
          >Search</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="10" sm="5" lg="10" col="10">
        <v-data-table
          v-if="search_results.length"
          show-group-by
          item-key="formId"
          :headers="headers"
          :items="search_results"
          :sort-by="['patientName']"
          :sort-desc="[false]"
          group-by="clinicName"
        >
          <template v-slot:[`item.visitDate`]="{ item }">
            {{ formatDateTime(item.visitDate) }}</template
          >
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th colspan="4">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].clinicName }}
            </th>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-icon
              title="Review patient visit"
              color="blue darken-2"
              @click="
                $router.push(
                  `/home/patient-form/${item.patientId}/${item.formId}`
                )
              "
            >
              mdi-clipboard-edit
            </v-icon></template
          >
        </v-data-table>
        <div v-if="no_results" style="font-weight: bolder">
          Query returned no results
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cookieWrapper from "../../auth/cookieWrapper";
import axios from "axios";
import dateTimeFormatter from "../../util/dateTimeFormatter";
export default {
  name: "AdvancedSearch",
  title: "Advanced Search",
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Clinic",
          value: "clinicName",
        },
        {
          text: "Patient",
          value: "patientName",
          groupable: false,
        },
        {
          text: "Visit date",
          value: "visitDate",
          groupable: false,
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          filterable: false,
          sortable: false,
          groupable: false,
        },
      ],
      selectedTab: 1,
      dates: [],
      searchTokenByName: "",
      search_results: [],
      no_results: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    allowSubmit() {
      switch (this.selectedTab) {
        case 0:
          if (this.dates.length != 2) return false;
          break;
        case 1:
          if (this.searchTokenByName == "" || this.searchTokenByName.length < 3)
            return false;
          break;
        case 2:
          return false;
        case 3:
          return false;
      }

      return true;
    },
  },
  methods: {
    formatDateTime(value) {
      return dateTimeFormatter.formatWithMask(value, "YYYY-MM-DD hh:mm A");
    },
    onTabChange() {
      /*switch (this.selectedTab) {
        case 0:
          this.searchName = "";
          break;
        case 1:
          this.dates = [];
      }*/
    },
    async onSearch() {
      const self = this;

      self.no_results = false;

      self.search_results = [];

      try {
        const token = cookieWrapper.getToken(self);

        switch (self.selectedTab) {
          case 0:
            self.search_results = await self.searchByDateRange(token);
            break;
          case 1:
            self.search_results = await self.searchByName(token);
            break;
        }

        if (self.search_results.length == 0) {
          self.no_results = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async searchByName(token) {
      const self = this;

      const payload = {
        q: self.searchTokenByName,
      };

      const config = {
        method: "post",
        url: `/api/search/byName?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      const { data } = await axios(config);

      return data;
    },

    async searchByDateRange(token) {
      const self = this;

      const payload = {
        dates: self.dates,
      };

      const config = {
        method: "post",
        url: `/api/search/byDateRange?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      const { data } = await axios(config);

      return data;
    },
  },
};
</script>